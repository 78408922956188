import { CustomResponseType } from 'types/common.type';
import {
  DebTransactionType,
  DepositTransactionType,
  TransactionType,
} from 'types/transaction.type';
import { isSuccessCode } from 'utils/common';

import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getListDebtTransactionInBuildingPagingAction,
  getListDepositTransactionInBuildingPagingAction,
  getListTransactionByClientPagingAction,
  getListTransactionInBuildingPagingAction,
  getListTransactionInContractPagingAction,
  getOverviewDebtTransactionInBuildingAction,
  getOverviewDepositTransactionInBuildingAction,
  getOverviewOtherTransactionInBuildingAction,
  getOverviewTransactionInBuildingAction,
} from './actions';
import { PAYMENT_TYPE } from 'enums';

interface TransactionState {
  listTransactionInContractPaging: { data: TransactionType[]; total: number };
  listTransactionInBuildingPaging: {
    data: TransactionType[];
    total: number;
  };
  overviewTransactionInBuilding: TransactionType;
  listTransactionByClientPaging: { data: TransactionType[]; total: number };
  listDepositTransactionInBuildingPaging: {
    data: DepositTransactionType[];
    total: number;
  };
  listDebtTransactionInBuildingPaging: {
    data: DebTransactionType[];
    total: number;
  };
  tabListValue: any;
}
const initialState: TransactionState = {
  listTransactionInContractPaging: {
    data: [],
    total: 0,
  },
  listTransactionInBuildingPaging: {
    data: [],
    total: 0,
  },
  overviewTransactionInBuilding: {},
  listTransactionByClientPaging: { data: [], total: 0 },
  listDepositTransactionInBuildingPaging: {
    data: [],
    total: 0,
  },
  listDebtTransactionInBuildingPaging: {
    data: [],
    total: 0,
  },
  tabListValue: PAYMENT_TYPE.INVOICE,
};
export const transactionSlice = createSlice({
  name: 'transaction',
  reducers: {
    resetListTransactionInContractPaging: (state) => {
      state.listTransactionInContractPaging = initialState.listTransactionInContractPaging;
    },
    saveTabList: (state, action) => {
      state.tabListValue = action.payload;
    },
    clearTabList: (state) => {
      state.tabListValue = initialState.tabListValue;
    },
  },
  initialState,
  extraReducers: (builder: ActionReducerMapBuilder<TransactionState>) => {
    builder.addCase(
      getListTransactionInContractPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionInContractPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListTransactionInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionInBuildingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewTransactionInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewTransactionInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewDepositTransactionInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewTransactionInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewOtherTransactionInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewTransactionInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getOverviewDebtTransactionInBuildingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.overviewTransactionInBuilding = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListTransactionByClientPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listTransactionByClientPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListDepositTransactionInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listDepositTransactionInBuildingPaging = action.payload.data;
        }
      },
    );
    builder.addCase(
      getListDebtTransactionInBuildingPagingAction.fulfilled,
      (state, action: PayloadAction<CustomResponseType>) => {
        if (isSuccessCode(action.payload.code)) {
          state.listDebtTransactionInBuildingPaging = action.payload.data;
        }
      },
    );
  },
});

export const { resetListTransactionInContractPaging, saveTabList, clearTabList } =
  transactionSlice.actions;
export default transactionSlice.reducer;
