import { getListOtherTransaction } from 'services/property/otherTransaction.service';
import {  PagingReq } from 'types/common.type';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportReq } from 'types/report.type';
import { OtherTransaction } from 'types/property.type';

export const getListOtherTransactionAction = createAsyncThunk(
  'property/getListOtherTransactionAction',
  async (payload: OtherTransaction & PagingReq & ReportReq) => {
    const response = await getListOtherTransaction(payload);

    return response.data;
  },
);
