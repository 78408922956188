import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableNormal from 'components/table/TableNormal';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/common';
import usePagination from 'hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { propertySelector } from 'store/property';
import { useDispatch, useSelector } from 'store/Store';

import {
  getListDebtTransactionInBuildingPagingAction,
  getOverviewDebtTransactionInBuildingAction,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, parseStringToDate, showData } from 'utils/common';

import { Box, Button, Grid, Typography } from '@mui/material';
import useExportExcel from 'hooks/useExportExcel';
import moment from 'moment';
import DateInput from 'components/shared/DateInput';
import { getListOtherTransactionAction } from 'store/property/actions/otherTransaction.action';

const TRANSACTION_TABLE_HEADER = ['stt', 'debt-price', 'note', 'creation-date'];

const dataInitial = {
  startDate: moment().startOf('month').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  transactionTypeId: '',
  paymentMethodId: '',
  searchValue: '',
  isIncome: '',
};

const DebtTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(dataInitial);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId } = useSelector(propertySelector);
  const { listDebtTransactionInBuildingPaging } = useSelector(transactionSelector);

  const { onExport } = useExportExcel();

  useEffect(() => {
    dispatch(
      getListOtherTransactionAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  useEffect(() => {
    dispatch(
      getListDebtTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  useEffect(() => {
    dispatch(
      getOverviewDebtTransactionInBuildingAction({
        buildingId,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [buildingId, dispatch, filterData.endDate, filterData.startDate]);

  const handleFilterData = useCallback(() => {
    dispatch(
      getListDebtTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,

        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [dispatch, buildingId, currentPage, rowsPerPage, filterData.startDate, filterData.endDate]);

  const handleChangeFilterData = (key: string) => (value: string) => {
    if (key === 'startDate') {
      const formattedStartDate = moment(parseStringToDate(value)).format('YYYY-MM-DD');
      const formattedEndDate = moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD');
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
        endDate: moment(formattedStartDate).isAfter(moment(formattedEndDate))
          ? value
          : filterData.endDate,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData, filterData]);

  const transactionTableRows = useMemo(() => {
    return listDebtTransactionInBuildingPaging.data.map((transaction) => {
      return [
        showData(transaction.id),
        formatPrice(String(transaction.price)),
        showData(transaction.note),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
      ];
    });
  }, [listDebtTransactionInBuildingPaging.data]);

  const headerConfig = {
    mainHeaders: TRANSACTION_TABLE_HEADER.map((title) => ({
      title: t(title),
    })),
  };

  const handleExportExcel = () => {
    const formattedDate = moment().format('DD:MM:YYYY_HH:MM:SS');

    const fileName = `Debt_Transactions_${formattedDate}`;

    onExport(transactionTableRows, fileName, headerConfig);
  };

  return (
    <Box>
      <Box
        sx={{
          flex: '1 1 100%',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing="12px">
          <Grid
            item
            lg={5}
            xs={12}
            sm={6}
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('startDate')}
                value={String(filterData.startDate)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign={'center'} py={1}>
                {t('to')}
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('endDate')}
                value={String(filterData.endDate)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportExcel}
          sx={{
            minWidth: 'auto',
            whiteSpace: 'nowrap',
            marginLeft: '12px',
          }}
        >
          {t('export-excel')}
        </Button>
      </Box>

      {/* Bảng hiển thị dữ liệu */}
      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listDebtTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};

export default DebtTransactionContainerTab;
