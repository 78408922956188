import _ from 'lodash';
import { centralGW } from 'services/axios.service';
import { PagingReq } from 'types/common.type';
import { OtherTransaction } from 'types/property.type';
import { ReportReq } from 'types/report.type';
import { getBranchId } from 'utils/common';

export const getListOtherTransaction = async (
  payload: OtherTransaction & PagingReq & ReportReq,
) => {
  const { buildingId, page, limit, isIncome, startDate, endDate, paymentMethodId } = payload;
  const branchId = getBranchId();

  return centralGW.get(`/api/v1/property/branch/${branchId}/building/${buildingId}/transactions`, {
    params: {
      page,
      limit,
      ...(!_.isEmpty(String(isIncome)) ? { isIncome } : {}),

      ...(startDate ? { startDate: startDate } : {}),
      ...(endDate ? { endDate: endDate } : {}),
      ...(paymentMethodId ? { paymentMethodId: paymentMethodId } : {}),
    },
    isDisableToast: true,
  });
};
