import React, { useCallback, useEffect, useMemo, useState } from 'react';

import TransactionChip from 'components/shared/TransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_FORMAT, DATE_TIME_FORMAT, EMPTY_CONTENT, IMAGE_URL } from 'constants/common';
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'enums';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getListOptionPaymentMethodAction, propertySelector } from 'store/property';
import { getListOtherTransactionAction } from 'store/property/actions/otherTransaction.action';
import { useDispatch, useSelector } from 'store/Store';
import { formatDate, formatPrice, parseStringToDate, showData } from 'utils/common';

import { Box, Button, Grid, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { IconAlignJustified, IconCreditCard, IconEye } from '@tabler/icons-react';
import ImagePopup from 'components/shared/ImagePopup';
import { useDisclosure } from 'hooks';
import useExportExcel from 'hooks/useExportExcel';
import moment from 'moment';
import DateInput from 'components/shared/DateInput';
import AutoComplete from 'components/shared/AutoComplete';
import { getOverviewOtherTransactionInBuildingAction } from 'store/transaction';
import _ from 'lodash';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'status',
  'collect',
  'expenses',
  'payment-method',
  'note',
  'image',
  'creation-date',
  'creator',
];

const dataInitial = {
  startDate: moment().startOf('month').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  transactionTypeId: '',
  paymentMethodId: '',
  searchValue: '',
  isIncome: '',
};

const OtherTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(dataInitial);
  const { listOtherTransactionPaging } = useSelector(propertySelector);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId, listPaymentMethod } = useSelector(propertySelector);
  const [image, setOpenImage] = useState<string[]>();

  const {
    isOpen: isOpenImage,
    onClose: onCloseImage,
    onOpen: onOpenDeleteTabConfirm,
  } = useDisclosure();
  const { onExport } = useExportExcel();

  useEffect(() => {
    dispatch(
      getListOtherTransactionAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  useEffect(() => {
    dispatch(
      getListOptionPaymentMethodAction({
        buildingId,
        isAllSystem: true,
      }),
    );
  }, [dispatch, buildingId]);

  useEffect(() => {
    dispatch(
      getOverviewOtherTransactionInBuildingAction({
        buildingId,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [buildingId, dispatch, filterData.endDate, filterData.startDate]);

  const handleOpenPopup = (images: string[]) => () => {
    setOpenImage(images);
    onOpenDeleteTabConfirm();
  };

  const handleFilterData = useCallback(() => {
    dispatch(
      getListOtherTransactionAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
        isIncome: !_.isEmpty(filterData.isIncome) ? Number(filterData.isIncome) : undefined,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
        paymentMethodId: filterData.paymentMethodId
          ? Number(filterData.paymentMethodId)
          : undefined,
      }),
    );
  }, [
    dispatch,
    buildingId,
    currentPage,
    rowsPerPage,
    filterData.isIncome,
    filterData.startDate,
    filterData.endDate,
    filterData.paymentMethodId,
  ]);

  const handleChangeFilterData = (key: string) => (value: string) => {
    if (key === 'startDate') {
      const formattedStartDate = moment(parseStringToDate(value)).format('YYYY-MM-DD');
      const formattedEndDate = moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD');
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
        endDate: moment(formattedStartDate).isAfter(moment(formattedEndDate))
          ? value
          : filterData.endDate,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData, filterData]);

  const transactionTableRows = useMemo(() => {
    return listOtherTransactionPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography variant="subtitle1">{showData(transaction.id)}</Typography>
          </Link>
        </Stack>,
        <TransactionChip
          key={uniqueId()}
          status={transaction.isIncome ? TRANSACTION_STATUS.INCOME : TRANSACTION_STATUS.EXPENSE}
        />,
        showData(transaction.isIncome ? formatPrice(String(transaction.transactionAmount)) : null),
        showData(!transaction.isIncome ? formatPrice(String(transaction.transactionAmount)) : null),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        transaction.note,
        <Tooltip title={t('view-image')} key={uniqueId()}>
          <IconButton
            color="info"
            disabled={transaction?.otherTransactionImages?.length === 0}
            onClick={handleOpenPopup(
              transaction?.otherTransactionImages?.map((img) =>
                String(`${IMAGE_URL}?id=${img?.imageUrl}`),
              ) ?? [''],
            )}
          >
            <IconEye size={21} />
          </IconButton>
        </Tooltip>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listOtherTransactionPaging.data]);

  const getTransactionTableRowsExcel = (data: any[]) => {
    return data.map((transaction) => {
      const status = transaction.isIncome ? TRANSACTION_TYPE.INCOME : TRANSACTION_TYPE.EXPENSE;
      const displayStatus = t(`transactionType.${status}`, EMPTY_CONTENT);

      return [
        showData(transaction.id),
        displayStatus,
        transaction.isIncome ? formatPrice(String(transaction.transactionAmount)) : '',
        !transaction.isIncome ? `-${formatPrice(String(transaction.transactionAmount))}` : '',
        showData(transaction.paymentMethod?.name),
        transaction.note,
        Array.isArray(transaction.otherTransactionImages) &&
        transaction.otherTransactionImages.length > 0
          ? t('has-image')
          : t('no-image'),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        showData(transaction.createdBy?.fullName),
      ];
    });
  };

  const headerConfig = {
    mainHeaders: TRANSACTION_TABLE_HEADER.map((title) => ({
      title: t(title),
    })),
  };

  const handleExportExcel = () => {
    const transactionTableRowsExcel = getTransactionTableRowsExcel(listOtherTransactionPaging.data);

    const formattedDate = moment().format('DD_MM_YYYY_HH_MM_SS');

    const fileName = `Other_Transactions_${formattedDate}`;

    onExport(transactionTableRowsExcel, fileName, headerConfig);
  };

  return (
    <Box>
      <Box
        sx={{
          flex: '1 1 100%',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing="12px">
          <Grid item xs={12} sm={6} lg={3}>
            <AutoComplete
              options={[
                {
                  label: t(TRANSACTION_TYPE.INCOME),
                  value: '1',
                },
                {
                  label: t(TRANSACTION_TYPE.EXPENSE),
                  value: '0',
                },
              ]}
              placeHolder="placeholder.choose-status"
              Icon={IconAlignJustified}
              value={filterData.isIncome}
              onChange={handleChangeFilterData('isIncome')}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <AutoComplete
              options={listPaymentMethod.map((method) => ({
                label: showData(method.name),
                value: String(method.id),
              }))}
              placeHolder="placeholder.choose-payment-method"
              Icon={IconCreditCard}
              value={filterData.paymentMethodId}
              onChange={handleChangeFilterData('paymentMethodId')}
            />
          </Grid>
          <Grid
            item
            lg={5}
            xs={12}
            sm={6}
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('startDate')}
                value={String(filterData.startDate)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign={'center'} py={1}>
                {t('to')}
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('endDate')}
                value={String(filterData.endDate)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportExcel}
          sx={{
            minWidth: 'auto',
            whiteSpace: 'nowrap',
            marginLeft: '12px',
          }}
        >
          {t('export-excel')}
        </Button>
      </Box>

      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listOtherTransactionPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
      <ImagePopup isOpen={isOpenImage} onClose={onCloseImage} images={image} />
    </Box>
  );
};
export default OtherTransactionContainerTab;
