import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import useExportExcel from 'hooks/useExportExcel';
import DepositTransactionChip from 'components/shared/DepositTransactionChip';
import TableNormal from 'components/table/TableNormal';
import { DATE_FORMAT, DATE_TIME_FORMAT, EMPTY_CONTENT } from 'constants/common';
import { ROUTES } from 'constants/router';
import { useDirection } from 'hooks';
import usePagination from 'hooks/usePagination';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getFloorInBuildingAction,
  getListOptionPaymentMethodAction,
  getListRoomInFloorAction,
  propertySelector,
} from 'store/property';
import { useDispatch, useSelector } from 'store/Store';
import {
  getListDepositTransactionInBuildingPagingAction,
  getOverviewDepositTransactionInBuildingAction,
  transactionSelector,
} from 'store/transaction';
import { formatDate, formatPrice, isAllow, parseStringToDate, showData } from 'utils/common';
import { PERMISSION } from 'constants/permission';
import { DEPOSIT_TRANSACTION_STATUS, DEPOSIT_TRANSACTION_TYPE } from 'enums';
import moment from 'moment';
import DateInput from 'components/shared/DateInput';
import { IconAlignJustified, IconCreditCard, IconDoor, IconStack2 } from '@tabler/icons-react';
import AutoComplete from 'components/shared/AutoComplete';

const TRANSACTION_TABLE_HEADER = [
  'transaction-code',
  'invoice-code',
  'booking-code',
  'status',
  'floor',
  'room',
  'collect',
  'expenses',
  'payment-method',
  'creation-date',
  'executor',
  'payer',
];

const dataInitial = {
  startDate: moment().startOf('month').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  transactionTypeId: '',
  paymentMethodId: '',
  searchValue: '',
  roomId: '',
  floorId: '',
  status: '',
};

const DepositTransactionContainerTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(dataInitial);
  const { rowsPerPage, currentPage, handleChangeLimit, handleChangeCurrentPage } = usePagination();
  const { buildingId, floorsInBuilding, listRoomInFloor, listPaymentMethod } =
    useSelector(propertySelector);
  const { listDepositTransactionInBuildingPaging } = useSelector(transactionSelector);

  const { goTo } = useDirection();
  const { onExport } = useExportExcel();

  useEffect(() => {
    dispatch(
      getListDepositTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, buildingId, rowsPerPage, currentPage]);

  useEffect(() => {
    if (filterData.floorId)
      dispatch(getListRoomInFloorAction({ buildingId, floorId: filterData.floorId }));
  }, [buildingId, dispatch, filterData.floorId]);

  useEffect(() => {
    dispatch(getFloorInBuildingAction({ buildingId }));

    dispatch(
      getListOptionPaymentMethodAction({
        buildingId,
        isAllSystem: true,
      }),
    );
  }, [buildingId, dispatch]);

  useEffect(() => {
    dispatch(
      getOverviewDepositTransactionInBuildingAction({
        buildingId,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
      }),
    );
  }, [buildingId, dispatch, filterData.endDate, filterData.startDate]);

  const handleFilterData = useCallback(() => {
    dispatch(
      getListDepositTransactionInBuildingPagingAction({
        buildingId,
        page: currentPage,
        limit: rowsPerPage,
        floorId: filterData.floorId,
        roomId: filterData.roomId,
        status: filterData.status,
        startDate: moment(parseStringToDate(filterData.startDate)).format('YYYY-MM-DD'),
        endDate: moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD'),
        paymentMethodId: filterData.paymentMethodId
          ? Number(filterData.paymentMethodId)
          : undefined,
      }),
    );
  }, [
    dispatch,
    buildingId,
    currentPage,
    rowsPerPage,
    filterData.roomId,
    filterData.floorId,
    filterData.status,
    filterData.startDate,
    filterData.endDate,
    filterData.paymentMethodId,
  ]);

  const handleChangeFilterData = (key: string) => (value: string) => {
    if (key === 'startDate') {
      const formattedStartDate = moment(parseStringToDate(value)).format('YYYY-MM-DD');
      const formattedEndDate = moment(parseStringToDate(filterData.endDate)).format('YYYY-MM-DD');
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
        endDate: moment(formattedStartDate).isAfter(moment(formattedEndDate))
          ? value
          : filterData.endDate,
      }));
    } else if (key === 'floorId')
      setFilterData((prev) => ({
        ...prev,
        roomId: '',
        [key]: value,
      }));
    else
      setFilterData((prev) => ({
        ...prev,
        [key]: value,
      }));
  };

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData, filterData]);

  const transactionTableRows = useMemo(() => {
    return listDepositTransactionInBuildingPaging.data.map((transaction) => {
      return [
        <Stack key={uniqueId()} direction="row" spacing={2} alignItems="center">
          <Link href="#" underline="none">
            <Typography color="textSecondary" variant="subtitle1">
              {showData(transaction.id)}
            </Typography>
          </Link>
        </Stack>,
        showData(transaction.depositInvoiceId),
        <Link key={uniqueId()} href="#" underline="none">
          <Typography color="textSecondary" variant="subtitle1">
            {showData(transaction.depositInvoice?.booking?.id)}
          </Typography>
        </Link>,
        <DepositTransactionChip key={uniqueId()} status={transaction.status} />,
        <Typography key={uniqueId()}>
          {showData(transaction.depositInvoice?.booking?.infoRoom?.floor?.name)}
        </Typography>,
        <Link
          key={uniqueId()}
          onClick={goTo(
            `${ROUTES.ROOM.LIST}/${
              isAllow([PERMISSION.GET_ROOM_DETAIL])
                ? transaction.depositInvoice?.booking?.infoRoom?.room?.id
                : null
            }`,
          )}
          underline="none"
        >
          {showData(transaction.depositInvoice?.booking?.infoRoom?.room?.name)}
        </Link>,
        showData(transaction.isDeposit ? formatPrice(String(transaction.transactionAmount)) : null),
        showData(
          !transaction.isDeposit ? `-${formatPrice(String(transaction.transactionAmount))}` : null,
        ),
        <Typography key={uniqueId()} color="textSecondary" fontWeight="400">
          {showData(transaction.paymentMethod?.name)}
        </Typography>,
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        <Link
          key={uniqueId()}
          onClick={goTo(
            `${ROUTES.CLIENT.LIST}/${
              isAllow([PERMISSION.GET_USER_DETAIL]) ? transaction.paidUser?.userId : null
            }`,
          )}
          underline="none"
        >
          <Typography variant="subtitle1">{showData(transaction.createdBy?.fullName)}</Typography>
        </Link>,
        <Link key={uniqueId()} href="#" underline="none">
          <Typography variant="subtitle1">{showData(transaction.paidUser?.fullName)}</Typography>
        </Link>,
      ];
    });
  }, [listDepositTransactionInBuildingPaging.data, goTo]);

  const getTransactionTableRowsExcel = (data: any[]) => {
    return data.map((transaction) => {
      const status =
        transaction.status === DEPOSIT_TRANSACTION_STATUS.DEPOSIT
          ? 'deposit'
          : transaction.status === DEPOSIT_TRANSACTION_STATUS.REFUND
          ? 'deposit-refund'
          : transaction.status === DEPOSIT_TRANSACTION_STATUS.CONVERT_TO_INVOICE_REVENUE
          ? 'convert-to-invoice-revenue'
          : 'convert-to-other-revenue';

      const displayStatus = t(`transactionStatus.${status}`, EMPTY_CONTENT);

      return [
        showData(transaction.id),
        showData(transaction.depositInvoiceId),
        showData(transaction.depositInvoice?.booking?.id),
        displayStatus,
        showData(transaction.depositInvoice?.booking?.infoRoom?.floor?.name),
        showData(transaction.depositInvoice?.booking?.infoRoom?.room?.name),
        transaction.isDeposit ? formatPrice(String(transaction.transactionAmount)) : '',
        !transaction.isDeposit ? `-${formatPrice(String(transaction.transactionAmount))}` : '',
        showData(transaction.paymentMethod?.name),
        formatDate(transaction.createdAt, DATE_TIME_FORMAT),
        showData(transaction.createdBy?.fullName),
        showData(transaction.paidUser?.fullName),
      ];
    });
  };

  const headerConfig = {
    mainHeaders: TRANSACTION_TABLE_HEADER.map((title) => ({
      title: t(title),
    })),
  };

  const handleExportExcel = () => {
    const transactionTableRowsExcel = getTransactionTableRowsExcel(
      listDepositTransactionInBuildingPaging.data,
    );

    const formattedDate = moment().format('DD_MM_YYYY_HH_MM_SS');

    const fileName = `Deposit_Transactions_${formattedDate}`;

    onExport(transactionTableRowsExcel, fileName, headerConfig);
  };

  return (
    <Box>
      <Box
        sx={{
          flex: '1 1 100%',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Grid container spacing="12px">
          <Grid item xs={6} sm={6} lg={1.75}>
            <AutoComplete
              options={floorsInBuilding.map((floor) => ({
                label: showData(floor.name),
                value: String(floor.id),
              }))}
              placeHolder="placeholder.choose-floor"
              Icon={IconStack2}
              value={filterData.floorId}
              onChange={handleChangeFilterData('floorId')}
            />
          </Grid>
          <Grid item xs={6} sm={6} lg={1.75}>
            <AutoComplete
              options={listRoomInFloor.map((floor) => ({
                label: showData(floor.name),
                value: String(floor.id),
              }))}
              placeHolder="placeholder.choose-room"
              Icon={IconDoor}
              value={filterData.roomId}
              onChange={handleChangeFilterData('roomId')}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={1.75}>
            <AutoComplete
              options={Object.keys(DEPOSIT_TRANSACTION_STATUS).map((status) => ({
                label: t(
                  showData(
                    DEPOSIT_TRANSACTION_TYPE[status as keyof typeof DEPOSIT_TRANSACTION_TYPE],
                  ),
                ),
                value: status,
              }))}
              placeHolder="placeholder.choose-status"
              Icon={IconAlignJustified}
              value={filterData.status}
              onChange={handleChangeFilterData('status')}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={1.75}>
            <AutoComplete
              options={listPaymentMethod.map((method) => ({
                label: showData(method.name),
                value: String(method.id),
              }))}
              placeHolder="placeholder.choose-payment-method"
              Icon={IconCreditCard}
              value={filterData.paymentMethodId}
              onChange={handleChangeFilterData('paymentMethodId')}
            />
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            sm={6}
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('startDate')}
                value={String(filterData.startDate)}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography textAlign={'center'} py={1}>
                {t('to')}
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <DateInput
                onChange={handleChangeFilterData('endDate')}
                value={String(filterData.endDate)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportExcel}
          sx={{
            minWidth: 'auto',
            whiteSpace: 'nowrap',
            marginLeft: '12px',
          }}
        >
          {t('export-excel')}
        </Button>
      </Box>

      <TableNormal
        header={TRANSACTION_TABLE_HEADER.map((cell) => t(cell))}
        data={transactionTableRows}
        total={listDepositTransactionInBuildingPaging.total}
        limit={rowsPerPage}
        currentPage={currentPage}
        onChangePage={handleChangeCurrentPage}
        onChangeLimit={handleChangeLimit}
      />
    </Box>
  );
};

export default DepositTransactionContainerTab;
